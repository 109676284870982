<template>
  <div>
    <b-card :title="$t('SearchOffical')">
      <b-row>
        <b-col md="12">
          <b-row>
            <b-col
              md="3"
              class="mt-1"
            >
              <div>
                <v-select
                  v-model="category"
                  :options="categories"
                  label="name"
                  class="w-100"
                  :placeholder="$t('ChooseType')"
                />
              </div>
            </b-col>
            <b-col
              md="2"
              class="mt-1"
            >
              <div>
                <div class="input-group">
                  <input
                    type="text"
                    v-model="filters.search"
                    class="form-control"
                    :placeholder="$t('DocumentNumber')"
                    aria-describedby="addon-wrapping"
                  >
                </div>
              </div>
            </b-col>
            <b-col
              md="3"
              class="mt-1"
            >
              <div class="input-group">
                <input
                  type="date"
                  class="form-control"
                  :placeholder="$t('StartDate')"
                  aria-describedby="addon-wrapping"
                  v-model="filters.start_date"
                >
              </div>
            </b-col>
            <b-col
              md="2"
              class="mt-1"
            >
              <div class="input-group">
                <input
                  type="date"
                  class="form-control"
                  :placeholder="$t('EndDate')"
                  aria-describedby="addon-wrapping"
                  v-model="filters.end_date"
                >
              </div>
            </b-col>
            <b-col
              md="1"
              class="mt-1"
            >
              <b-button variant="primary" @click="getSearchDocument">
                {{ $t('Search') }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col
        v-for="(cat, i) in categories"
        :key
        md="4"
        sm="4"
        cols="4"
        class="text-center mt-2"
      >
        <b-button
          :variant="color[i]"
          size="lg"
          class="w-75"
          @click="$router.push({name:'DocumentsByCategory',params:{id:cat.id}})"
        >
          {{ cat.name }}
        </b-button>
      </b-col>
    </b-row>

    <b-card class="mt-3" v-if="datas.length">
      <b-table
        striped
        hover
        :items="datas"
        :fields="fields"
        responsive=""
      >
        <template #cell(register_date)="{item}">
          <span class="d-flex justify-content-center mt-1">
            <span>
              {{ item.register_date | dateFormat }}
            </span>
            <span class="ml-1"><b-icon icon="calendar2-week" /></span>
          </span>
        </template>
        <template #cell(cur_status)="{item}">
          <span>
            {{ item.cur_status.name }}
          </span>
        </template>
        <template #cell(action)="data">
          <b-button
            size="sm"
            class="mr-1"
            variant="primary"
            @click="$router.push({ name: 'DocumentParagraphByYear', params: { id:data.item.id } })"
          >
            <b-icon icon="eye" />
          </b-button>
        </template>
      </b-table>
      <div class="overflow-auto text-center">
        <b-pagination
            v-model="currentPage"
            align="center"
            :total-rows="total"
            :per-page="perPage"
            first-number
            size="lg"
            class="pt-2"
            @input="getSearchDocument"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'
// import { fetchUsersList } from '../api/auth'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { getCategories, getDocs } from '@/api/documents'

export default {
  components: {
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BLink,
    vSelect,
  },
  data() {
    return {
      categories: [],
      category: null,
      datas: [],
      color: ['success', 'primary', 'info'],
      perPage: 15,
      currentPage: 1,
      total:1,
      filters: {
        search: '',
        category_id: null,
        year_id: null,
        status_id: null,
        start_date: null,
        end_date: null,
        type: 'user',
      },
      fields: [
        {
          key: 'id',
          label: this.$t('ID'),
        },
        {
          key: 'number',
          label: this.$t('Number'),
        },
        {
          key: 'name',
          label: 'Название',
        },
        {
          key: 'year.year',
          label: this.$t('Year'),
        },
        {
          key: 'register_date',
          label: this.$t('Register_Date'),
        },
        {
          key: 'cur_status',
          label: this.$t('Current_status'),
        },
        {
          key: 'action',
          label: this.$t('Action'),
        },
      ],
    }
  },
  created() {
    getCategories().then(res => {
      this.categories = res.data.data
    })
  },
  methods: {
    fetchList() {
      getDocs({ ...this.filters, ...{ page: this.currentPage } }).then(({ data }) => {
        this.datas = data.data.data
        if (data.data.total) {
          this.total = data.data.total
        }
      })
    },
    getSearchDocument() {
      this.filters.category_id = this.category ? this.category.id : null;
      this.currentPage = 1
      this.fetchList()
    }
  }
}
</script>

<style>
.btn_danger{
  margin-top:10px;
}
</style>
